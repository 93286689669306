.relatorio table {
    border-collapse: separate;
    border :  0px solid #000000;
    border-spacing: 0;
    font-size: 11pt;
    width: 100%;
    border-color:  #000000 ;
    border-right: 1px solid;
}

.relatorio tr {
 border-color:  #000000 ;
 border-style: none ;
 border-width: 0 ;
}

.relatorio td {
 border-color:  #000000 ;
 border-left: 1px solid;
 border-bottom:1px solid ;
}

.relatorio th {
 border-color:  #000000 ;
 border-left: 1px solid;
 border-top:1px solid ;
 border-bottom:1px solid ;
}