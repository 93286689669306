.logo {
    width: 150px;
}

.headerLogin{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.tableRelatorio table, 
.tableRelatorio th, 
.tableRelatorio td {
    border: 1px solid black;
    border-collapse: collapse;
 }